/**
 * if no active subscription, the subscription endpoint returns the following:
 *
 */
export interface UserSubscriptionHistory {
  subscription: {
    active: boolean;
    provisionalPlan?: string;
    freeTrialEligible: boolean;
  };
}

export enum MockTestStatus {
  Assigned = 'assigned',
  Unassigned = 'unassigned',
  Completed = 'completed',
  InProgress = 'in progress',
}
export interface MockTest {
  testId: string;
  testName: string;
  testLength: number;
  testStatus: MockTestStatus;
}

/**
 * if active subscription, the subscription endpoint returns the following:
 *
 */
export interface UserCurrentSubscription {
  id: string;
  active: boolean;
  subscriptionTotal: number | null;
  nextInvoiceAmount: number | null;
  nextInvoiceDueAt: number | null;
  cancelOn: number | null;
  trialEndDate: number | null;
  subscriptionCredit: number;
  discount: number;
  subscriptionId: string;
  latestPaymentFailed: boolean;
  lastPaymentAttemptAt: number | null;
  products: PurchasedProduct[];
  recurringInterval: Exclude<SubscriptionType, SubscriptionType.None>;
  recurringIntervalCount: number;
  quotaResetDate: number;
}
export interface PurchasedProduct {
  subscriptionItem: {
    itemId: string;
    productId: string;
    priceId: string;
    planName: string;
    productKey: ProductKey;
    subscriptionPlanId: string;
  };
  learner?: {
    userId: string;
    date?: Date | string;
    firstName?: string;
    lastName?: string;
    avatar?: string;
    userName?: string;
    courseLength?: number;
    tests: MockTest[];
    quotaReached: boolean;
    quotaRemaining: number;
    quota: number;
    dateOfBirth?: string | Date;
  };
}

export enum ProductKey {
  CENTURY = 'century-unlimited',
  Bond = 'bond-premium',
  BondPro = 'bond-premium-pro',
}

export enum PartnerSource {
  Bond = 'bond',
}

export enum SubscriptionType {
  None = 'none',
  Monthly = 'month',
  Annually = 'year',
}

export enum DateFormatBySubscriptionType {
  DateMonth = 'd MMM',
  DateMonthYear = 'd MMM yyyy',
}
