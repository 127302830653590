import { Button } from '@ctek/design-system';
import { useRef, useEffect, useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { AlternativeBoardMatchingWithoutBox } from 'century-core/core-components/DraggableQuestionTypes/components/AlternativeBoardMatching/AlternativeBoardMatching';
import { ResultTypes } from 'century-core/core-components/DraggableQuestionTypes/components/LabelPair/LabelPair';
import { ComposablePromptAnswerList } from 'century-core/core-components/DraggableQuestionTypes/components/PromptAnswerList/PromptAnswerList';
import PromptAnswerPair from 'century-core/core-components/DraggableQuestionTypes/components/PromptAnswerPair/PromptAnswerPair';
import UploadedImage from 'century-core/core-components/DraggableQuestionTypes/components/UploadedImage/UploadedImage';
import MatchingAnswerDraggable from 'layout/elementsLearn/MatchingAnswerDraggable/MatchingAnswerDraggable';
import SmallRichTextEditor from 'layout/textEditors/SmallRichTextEditor/SmallRichTextEditor';
import { emptyDelta } from 'century-core/core-utils/utils/quill/constants';
import Feedback from 'century-core/core-components/Feedback/Feedback';
import { NoImagePlaceholder } from '../Labelling/DroppableLabelPair';
import { DisableOnClickButton } from '../Labelling/LabellingQuestionTypeReadonly';
import { AnsweredItem, DraggableQuestionTypeReadOnlyProps, PossibleAnswer } from '../../../typings/DraggableQuestionTypes';
import CachedImageUrlContext, { provideCachedGetImageUrlContext } from 'century-core/media/hooks/useGetImagePublicUrlCache';
import useQuestionTypesButtonHandling from '../useQuestionTypesButtonHandling';
import { getPossibleAnswerById, getPolymerReactToken, getAnswerStatusForQuestion } from '../utils';
import { ImageMatching, RenderMatchingAnswerDraggable } from './MatchingQuestionType';

export const MatchingQuestionTypeReadonly = (props: DraggableQuestionTypeReadOnlyProps) => {
  const { dragComponent, data, question, result, revealData, withholdResults } = props;

  const nextQuestionBtn = useRef();
  const viewResultsBtn = useRef();

  useEffect(() => {
    if (nextQuestionBtn.current) {
      (nextQuestionBtn.current! as any).focus();
    }

    if (viewResultsBtn.current) {
      (viewResultsBtn.current! as any).focus();
    }
  }, []);

  const { answerGroups, content, possibleAnswers } = question.data;
  const answeredItems = result?.details ? result.details : [];
  const answerStatus: ResultTypes | null = getAnswerStatusForQuestion(answeredItems.length > 0, result, withholdResults)

  const quillDelta: object = content[0]?.data || emptyDelta;

  const answers = answerGroups[0].answers;

  const { handleClickNext, handleViewResults, buttonDisabled } = useQuestionTypesButtonHandling({
    handleClickNext: data && data.handleClickNext,
    handleViewResults: data && data.handleViewResults,
  });

  const renderFooterButtons = () => {
    if (!data || !(data.handleClickNext || data.handleViewResults)) {
      return null;
    }

    return (
      <>
        {data.handleClickNext && (
          <Button
            innerRef={nextQuestionBtn}
            type="button"
            onClick={handleClickNext}
            loading={buttonDisabled}
            variant="secondary"
            qa="button-next-question"
          >
            <FormattedMessage id="question-labelling-next-button" defaultMessage="Next Question" />
          </Button>
        )}
        {data.handleViewResults && (
          <DisableOnClickButton
            innerRef={viewResultsBtn}
            type="button"
            onClick={handleViewResults}
            loading={buttonDisabled}
            variant="secondary"
            qa="button-view-results"
          >
            <FormattedMessage id="question-labelling-view-results" defaultMessage="View Results" />
          </DisableOnClickButton>
        )}
      </>
    );
  };

  return (
    <AlternativeBoardMatchingWithoutBox qa="matching-question-board">
      {{
        additionType: dragComponent as Ctek.MatchType,
        additionals: renderAdditionals(answeredItems, possibleAnswers, dragComponent as Ctek.MatchType),
        feedback:
          answerStatus !== ResultTypes.SKIPPED && answerStatus !== null ? (
            <Feedback
              answerStatus={answerStatus}
              feedback={
                data && data.feedback ? (
                  <SmallRichTextEditor
                    onChange={(newVal: any) => {
                      return;
                    }}
                    readonly={true}
                    value={data.feedback}
                  />
                ) : undefined
              }
              buttonDisabled={buttonDisabled}
              reveal={!!revealData?.correctAnsweredItems}
              revealError={revealData?.revealError}
              handleRevealClicked={revealData?.handleQuestionRevealsClicked}
            />
          ) : undefined,
        footerControls: renderFooterButtons(),
        matchingPairs: (
          <MatchingPairs
            dragComponent={dragComponent as Ctek.MatchType}
            answers={answers as Ctek.Answer[]}
            answeredItems={answeredItems}
            possibleAnswers={possibleAnswers}
            withholdResults={withholdResults}
          />
        ),
        question: (
          <SmallRichTextEditor
            onChange={() => {
              return;
            }}
            value={quillDelta}
            readonly={true}
          />
        ),
        revealCorrect: !!revealData?.correctAnsweredItems && !withholdResults ? (
          <MatchingPairs
            dragComponent={dragComponent as Ctek.MatchType}
            answers={answers as Ctek.Answer[]}
            answeredItems={(revealData?.correctAnsweredItems as AnsweredItem[]) || []}
            possibleAnswers={possibleAnswers}
            isReveal={true}
          />
        ) : undefined,
      }}
    </AlternativeBoardMatchingWithoutBox>
  );
};

const MatchingPairs = ({
  dragComponent,
  answers,
  answeredItems,
  possibleAnswers,
  isReveal,
  withholdResults,
}: {
  dragComponent: Ctek.MatchType;
  answers: Ctek.Answer[];
  answeredItems: AnsweredItem[];
  possibleAnswers: PossibleAnswer[];
  isReveal?: boolean;
  withholdResults?: boolean;
}) => {
  return (
    <ComposablePromptAnswerList>
      {{
        promptAnswerPairs: answers.map((answer, index) => {
          const relatedAnsweredItem = answeredItems.find(item => item.answerGroupAnswerId === answer.id);
          const result = relatedAnsweredItem && !withholdResults // TODO need to clearly state withholding results does not mean skipped
            ? relatedAnsweredItem.isCorrect
              ? ResultTypes.CORRECT
              : ResultTypes.INCORRECT
            : ResultTypes.SKIPPED;
          const possibleAnswer = relatedAnsweredItem
            ? getPossibleAnswerById(relatedAnsweredItem.possibleAnswerId, possibleAnswers)
            : undefined;
          return (
            <li className="prompt-answer-list__item" key={index}>
              {dragComponent === ('text' as Ctek.MatchType) ? (
                <TextMatchingPair answer={answer} possibleAnswer={possibleAnswer} result={isReveal ? 'reveal' : result} />
              ) : (
                <ImageMatchingPair answer={answer} possibleAnswer={possibleAnswer} result={isReveal ? 'reveal' : result} />
              )}
            </li>
          );
        }),
      }}
    </ComposablePromptAnswerList>
  );
};

const renderAdditionals = (answeredItems: AnsweredItem[], possibleAnswers: PossibleAnswer[], dragComponent: Ctek.MatchType) => {
  if (answeredItems.length === possibleAnswers.length) {
    return undefined;
  }
  return possibleAnswers.map((possibleAnswer, index) => {
    const isMatched = answeredItems.find(item => {
      return item.possibleAnswerId === possibleAnswer.id;
    });

    return isMatched ? (
      <div style={{ visibility: 'hidden', height: '0px' }}>
        {dragComponent === ('text' as Ctek.MatchType) ? (
          <RenderMatchingAnswerDraggable possibleAnswer={possibleAnswer} hideDragHandle={true} />
        ) : (
          <ImageMatching possibleAnswer={possibleAnswer} />
        )}
      </div>
    ) : dragComponent === ('text' as Ctek.MatchType) ? (
      <RenderMatchingAnswerDraggable possibleAnswer={possibleAnswer} hideDragHandle={true} />
    ) : (
      <div className="draggable-label-item draggable-label-item__active">
        <ImageMatching possibleAnswer={possibleAnswer} />
      </div>
    );
  });
};

const TextMatchingPair = ({
  answer,
  possibleAnswer,
  result,
}: {
  answer: Ctek.Answer;
  possibleAnswer: PossibleAnswer | undefined;
  result: ResultTypes | 'reveal';
}) => {
  return (
    <PromptAnswerPair result={result}>
      {{
        itemType: 'text' as Ctek.MatchType,
        pair: [
          <MatchingAnswerDraggable key={answer.id}>
            {{
              hideDragHandle: true,
              value: answer.content?.[0].data,
            }}
          </MatchingAnswerDraggable>,

          possibleAnswer ? (
            <MatchingAnswerDraggable key={possibleAnswer ? possibleAnswer.id : `possible-answer=${answer.id}`}>
              {{
                hideDragHandle: true,
                value: possibleAnswer ? possibleAnswer.content[0].data : emptyDelta,
              }}
            </MatchingAnswerDraggable>
          ) : (
            <MatchingAnswerDraggable key={`possible-answer=${answer.id}`}>
              {{
                hideDragHandle: true,
                value: emptyDelta,
              }}
            </MatchingAnswerDraggable>
          ),
        ],
      }}
    </PromptAnswerPair>
  );
};

const ImageMatchingPair = ({
  answer,
  possibleAnswer,
  result,
}: {
  answer: Ctek.Answer;
  possibleAnswer: PossibleAnswer | undefined;
  result: ResultTypes | 'reveal';
}) => {
  const [answerGroupImgSrc, setAnsGroupImgSrc] = useState(null as string | null);
  const [possibleAnswerImgSrc, setPossibleAnswerImgSrc] = useState(null as string | null);
  // TODO: refactor - when QLA is in React, we can get auth in a more conventional way
  const token = getPolymerReactToken();
  const { getMediaPublicUrl } = useContext(CachedImageUrlContext);

  useEffect(() => {
    // Create an scoped async function in the hook
    async function fetchUrls() {
      const ansGroupImgSrc =
        (answer.content?.[0].data as Ctek.EnrichedContentData).value?.files[0].url ||
        ((await getMediaPublicUrl(answer.content?.[0].id as string, token)) as string);
      setAnsGroupImgSrc(ansGroupImgSrc);

      if (!possibleAnswer) {
        return;
      }
      const possibleAnsImgSrc =
        (possibleAnswer.content[0].data as any).value?.files[0].url ||
        ((await getMediaPublicUrl(possibleAnswer.content[0].id, token)) as string);
      setPossibleAnswerImgSrc(possibleAnsImgSrc);
    }
    // Execute the created function directly
    fetchUrls();
  }, [answer.content, getMediaPublicUrl, possibleAnswer, token]);

  return (
    <PromptAnswerPair result={result}>
      {{
        itemType: 'image',
        pair: [
          <UploadedImage key={answer.id}>
            {{
              imgSrc: answerGroupImgSrc || null,
            }}
          </UploadedImage>,
          possibleAnswer && possibleAnswerImgSrc ? (
            <UploadedImage>
              {{
                imgSrc: possibleAnswerImgSrc,
              }}
            </UploadedImage>
          ) : (
            <NoImagePlaceholder />
          ),
        ],
      }}
    </PromptAnswerPair>
  );
};

// TODO - remove when polymer QLA is removed.
const MatchingQuestionTypeReadonlyWrapper = (props: DraggableQuestionTypeReadOnlyProps) => {
  if (!props.question || !props.result || props.question.data.answerType !== 'matching') {
    return null;
  }
  return <MatchingQuestionTypeReadonly {...props} />;
};
// TODO - remove when polymer QLA is removed.
export const PolymerMatchingQuestionTypeReadonly = provideCachedGetImageUrlContext(MatchingQuestionTypeReadonlyWrapper);
