import { SearchBox as SearchBoxComponent, Button, searchIcon } from '@ctek/design-system';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
interface Props {
  performSearch: (value: string) => void;
  placeholderId?: string;
  defaultPlaceholder?: string;
  trackIconClick?: () => void;
  initialValue?: string;
  minSearchLength?: number;
  searchTimeout?: number;
}

let searchTimer: NodeJS.Timer;

export const SearchBox = (props: Props) => {
  const {
    initialValue,
    performSearch,
    trackIconClick,
    placeholderId,
    defaultPlaceholder,
    minSearchLength = 3,
    searchTimeout = 1000,
  } = props;

  const intl = useIntl();
  const [query, setQuery] = useState(initialValue || '');

  useEffect(() => {
    setQuery(initialValue || '');
  }, [initialValue]);

  const onSearchChange = (e: { target: { value: string } }): void => {
    const { value } = e.target;
    setQuery(value);
    if (!value.length || value.length >= minSearchLength) {
      clearTimeout(searchTimer);
      searchTimer = setTimeout(() => {
        performSearch(e.target.value || '');
      }, searchTimeout);
    }
  };

  const handleKeypress = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      performSearch(e.target.value);
    }
  };

  const onIconClick = () => {
    if (query) {
      performSearch(query);
    }
    trackIconClick?.();
  };

  const messageId = placeholderId || 'search-component-search';
  const defaultMessage = defaultPlaceholder || 'Search';
  return (
    <SearchBoxComponent>
      <input
        type="search"
        placeholder={intl.formatMessage({ id: messageId, defaultMessage })}
        value={query}
        onChange={onSearchChange}
        onKeyPress={handleKeypress}
        data-testid="search-input"
      />
      <Button
        aria={intl.formatMessage({ id: 'search-component-search', defaultMessage: 'Search' })}
        size="tiny"
        type="button"
        variant="secondary"
        icon={searchIcon}
        onClick={onIconClick}
        disabled={query.length <= 0}
        qa="search-btn"
      />
    </SearchBoxComponent>
  );
};
