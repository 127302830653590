/**
 * Keys are defined by Data team in the Mixpanel: Taxonomy
 *
 * https://docs.google.com/spreadsheets/d/1wTJ1Y6jNO-xLM1zSgZCpaEY5IA95ZUx4Ih_5FT4TRmQ/
 */

export enum MixpanelKeys {
  AddedFeedback = 'Added Feedback',
  AdditionalOptions = 'Additional Options',
  AppArea = 'App Area',
  AppType = 'App Type',
  AppVersion = 'App Version',
  AssessmentId = 'Assessment Id',
  AssessmentName = 'Assessment Name',
  AssignmentId = 'Assignment Id',
  AssigneeId = 'Assignee Id',
  AssigneeType = 'Assignee Type',
  AvatarOld = 'Old Avatar',
  AvatarNew = 'New Avatar',
  AssignmentInitialCreator = 'Assignment Initial Creator',
  BoardsSelected = 'Boards Selected',
  ClassCategoryId = 'Class Category Id',
  ClassCategoryName = 'Class Category Name',
  Column = 'Column',
  CourseId = 'Course Id',
  CourseName = 'Course Name',
  Courses = 'Courses',
  CoursesChosen = 'Courses Chosen',
  DependantId = 'Dependant Id',
  Duration = 'Duration',
  ActionTime = 'Action Time',
  FeedbackLocation = 'Feedback Location',
  FilterType = 'Filter Type',
  ImageSrc = 'Image src',
  RetryAttempt = 'Retry Attempt',
  // preserving the lower cap as not sure if mixpanel is sensitive or not
  GoalAdded = 'Goal added',
  GoalDeleted = 'Goal Deleted',
  GoalId = 'Goal Id',
  IsAscending = 'Is Ascending',
  IsAssignmentGroup = 'Is Assignment Group',
  IsEnabled = 'Is Enabled',
  IsOrdered = 'Is Ordered',
  IsTest = 'Is Test',
  LearnerId = 'Learner Id',
  Level = 'Level',
  LoginSessionId = 'Login Session Id',
  MatchType = 'Match Type',
  NavigationType = 'Navigation Type',
  NumberOfClasses = 'Number Of Classes',
  NuggetId = 'Nugget Id',
  NuggetName = 'Nugget Name',
  NumberAnswers = 'Number Answers',
  NumberOfCertificates = 'Number of Certificates',
  OptionChanged = 'Option Changed',
  OrganisationId = 'Organisation Id',
  OrganisationName = 'Organisation Name',
  PageName = 'Page Name',
  PageArea = 'Page Area',
  ParentQuestionId = 'Parent Question Id',
  Path = 'Path',
  QuestionGroupId = 'Question Group Id',
  QuestionId = 'Question Id',
  Questions = 'Questions',
  QuestionType = 'Question Type',
  Role = 'Role',
  SearchItem = 'Search Item',
  SearchTerm = 'Search Term',
  SelectedOption = 'Selected Option',
  SelectedNugget = 'Selected Nugget',
  SelectedMetric = 'Selected Metric',
  SelectedMonth = 'Selected Month',
  SelectedTimeFilter = 'Selected Time Filter',
  SelectedTimeMetric = 'Selected Time Metric',
  SelectedTimeStart = 'Selected Time Start',
  SelectedValue = 'Selected Value',
  SortedColumn = 'Sorted Column',
  SortOrder = 'Sort Order',
  StrandId = 'Strand Id',
  StrandName = 'Strand Name',
  StudentId = 'Student Id',
  SubjectGroupId = 'Subject Group Id',
  SubjectGroupName = 'Subject Group Name',
  SubjectId = 'Subject Id',
  SubjectName = 'Subject Name',
  TotalBoardsAvailable = 'Number Boards Available',
  UserId = 'User Id',
  Value = 'Value',
  // B2C
  PlanType = 'Plan Type',
  SelectedPlans = 'Selected Plans',
  UserStatus = 'User Status',
  CouponId = 'Coupon Id',
  CouponDuration = 'Coupon Duration',
  PromotionCode = 'Promotion Code',
  PromotionCodeId = 'Promotion Code Id',
  PriceId = 'Price Id',
  ProductId = 'Product Id',
  ProductItemId = 'Product Item Id',
  ProductName = 'Product Name',
  ProductKey = 'Product Key',
  IsTrial = 'Is Trial',
  SubscriptionId = 'Subscription Id',
  SubscribedPlans = 'Subscribed Plans',
  HasDiscount = 'Has Discount',
  HasCredit = 'Has Credit',
  FailedPayment = 'Failed Payment',
  NewPlan = 'New Plan',
  RemovedPlan = 'Removed Plan',
  NewPlanKey = 'New Plan Key',
  RemovedPlanKey = 'Removed Plan Key',
  ReactivatedPlan = 'Reactivated Plan',
  SubscriptionSetupMode = 'Subscription Setup Mode',
  ReasonForCancelling = 'Reason for Cancelling',
  CenturyUnlimitedQuantity = 'century-unlimited Quantity',
  BondPremiumQuantity = 'bond-premium Quantity',
  BondProQuantity = 'bond-premium-pro Quantity',
  SubscriptionType = 'Subscription Type',
  SubscriptionCtxLegacyUser = 'Subscription: Legacy User',
  SubscriptionCtxStripeCustomerId = 'Subscription: Customer Id',
  SubscriptionCtxStripeSubscriptionId = 'Subscription: Id',
  SubscriptionCtxIsTrial = 'Subscription: Is Trial',
  SubscriptionCtxTrialEnd = 'Subscription: Trial End',
  SubscriptionCtxIsActive = 'Subscription: Is Active',
  SubscriptionCtxCancellationDate = 'Subscription: Cancellation Date',
  SubscriptionCtxPastDue = 'Subscription: Past Due',
  SubscriptionCtxPrice = 'Subscription: Price',
  SubscriptionCtxDependants = 'Subscription: Active Dependants',
  SubscriptionCtxTotalPlans = 'Subscription: Total Plans',
  SubscriptionCtxCenturyUnlimitedQuantity = 'Subscription: century-unlimited Quantity',
  SubscriptionCtxCenturyUnlimitedUnassigned = 'Subscription: century-unlimited Unassigned',
  SubscriptionCtxBondPremiumQuantity = 'Subscription: bond-premium Quantity',
  SubscriptionCtxBondPremiumUnassigned = 'Subscription: bond-premium Unassigned',
  SubscriptionCtxBondProQuantity = 'Subscription: bond-premium-pro Quantity',
  SubscriptionCtxBondProUnassigned = 'Subscription: bond-premium-pro Unassigned',
  LearnerProductKey = 'Learner: Product Key',
  MockTests = 'Mock Tests',
  ControlLocation = 'Control Location',
  AccountSwitchedFrom = 'Account Switching From',
  AccountSwitchedTo = 'Account Switching To',
  AccountSwitchedRoleTo = 'Account Switching Role To',
  AccountSwitchedRoleFrom = 'Account Switching Role From',
  AccountSwitchedSource = 'Account Switch Source',
  AccountSwitchLocation = 'Account Switch Location',
  ProductSwitchPromotionName = 'Product Switch Promotion Name',
  ProductSwitchPromotionPlan = 'Product Switch Promotion Plan',
  ProductSwitchPromotionCode = 'Product Switch Promotion Code',
  ProductSwitchPromotionModalOpen = 'Product Switch Promotion Modal Open',
  ProductSwitchPromotionModalClose = 'Product Switch Promotion Modal Close',
  ProductSwitchPromotionModalContinue = 'Product Switch Promotion Modal Continue',
  PromotionLinkUsed = 'Promotion Link Used',
  // Study Session
  StudySessionId = 'Study Session Id',
  ClassId = 'Class Id',
  ClassName = 'Class Name',
  CourseSubject = 'Course Subject',
  CourseLevel = 'Course Level',
  CourseType = 'Course Type',
  StartedFrom = 'Started From',
  IsDiagnostic = 'Is Diagnostic',
  IsInteractive = 'Is Interactive',
  IsNew = 'Is New',
  FragmentId = 'Fragment Id',
  FragmentType = 'Fragment Type',
  DocumentId = 'Document Id',
  DocumentType = 'Document Type',
  Action = 'Action',
  CoursesCompleted = 'Courses Completed',
  CoursePlanId = 'Course Plan Id',
  DownloadedFrom = 'Downloaded From',
  Rating = 'Rating',
  ViewedLocation = 'Viewed Location',
  RetryStudySessionCount = 'Retry Study Session Count',
  // ELA
  ElaArea = 'Ela Area',
  Emotion = 'Emotion',
  EmotionId = 'Emotion Id',
  // Badges and Streaks
  BadgeId = 'Badge Id',
  BadgeName = 'Badge Name',
  BadgeAchieved = 'Badge Achieved',
  IsNewBadge = 'Is New Badge',
  NewBadgesIds = 'New Badges Ids',
  NewBadgesNames = 'New Badges Names',
  StreakId = 'Streak Id',
  StreakName = 'Streak Name',
  NewState = 'newState',
  Property = 'property',
  // Cognitive Message
  CognitiveMessageReaction = 'Reaction',
  CognitiveMessageCode = 'Message Code',
  CognitiveMessageSessionsLastWeek = 'Sessions Last Week',
  CognitiveMessageSessionsLastMonth = 'Session Last Month',
  CognitiveMessageAvgSessionDuration = 'Avg Session Duration',
  CognitiveMessageAvgSessionScore = 'Avg Session Score',
  // Screen Fiter
  ScreenFilter = 'Screen Filter',
  // RLP
  RLPReason = 'RLP Reason',
  NuggetIndex = 'Nugget Index',

  // RLP Subjects Filter
  SubjectsSelected = 'Subjects Selected',
  SubjectsDeselected = 'Subjects Deselected',
  SubjectsChanged = 'Subjects Changed',

  // Teacher Assessment
  FilteredStudentName = 'Filtered Student Name',
  FilteredByCompletion = 'Filtered by Completion',
  FilteredByFlagged = 'Filtered by Flagged',

  // Class Admin
  TeacherId = 'Teacher Id',
  UsersList = 'Users List',
  classPropertiesType = 'Class Properties Type',
  ModalName = 'Modal Name',
  ModalAction = 'Modal Action',
  SubjectDropdown = 'Subject Dropdown',
  LevelDropdown = 'Level Dropdown',
  SubjcetSelected = 'Subjcet Selected',
  LevelSelected = 'Level Selected',
  CourseExpanded = 'Course Expanded',
  SearchBar = 'Search Bar',
  SearchIcon = 'Search Icon',
  SelectButton = 'Select Button',
  TotalSelected = 'Total Selected',
  TotalClasses = 'Total Classes',
  TotalCourses = 'Total Courses',
  UpdateType = 'Update Type',
  GuardianId = 'Guardian Id',
  ClassCode = 'Class Code',
  CodeExpiry = 'Code Expiry',
  OldClassName = 'Old Class Name',
  NewClassName = 'New Class Name',
  IncludeEmail = 'Include Email',
  IncludeUsers = 'Include Users',
  ItemType = 'Item Type',
  ItemId = 'ItemId',
  JobId = 'Job Id',
  JobType = 'Job Type',
  TemplateName = 'Template Name',
  SelectedOrganisationId = 'Selected Organisation Id',
  SelectedOrganisationName = 'Selected Organisation Name',
  CreatedNewUserId = 'Created New User Id',
  ImportType = 'Import Type',
  UploadOrganisationId = 'Upload Organisation Id',
  UploadOrganisationName = 'Upload Organisation Name',
  TriggerId = 'Trigger Id',
  UploadedUserId = 'Uploaded User Id',
  DetailsAdded = 'New Student details filled',
  DetailsEdited = 'Details Edited',

  // Student Smart Assessment
  SmartAssessmentId = 'Smart Assessment Id',
  SmartAssessmentName = 'Smart Assessment Name',
  SmartAssessmentSessionId = 'Smart Assessment Session Id',
  SmartAssessmentNodeId = 'Smart Assessment Node Id',
  SmartAssessmentSection = 'Smart Assessment Section',
  SmartAssessmentStartVideoAction = 'Smart Assessment Start Video Action',
  NumberOfWords = 'Number of Words',
  LevelAchieved = 'Level Achieved',
  CohortName = 'Cohort Name',

  // Assessments
  TestId = 'Test Id',
  SectionId = 'Section Id',
  NewTimerState = 'New Timer State',
  AnswerAction = 'Answer Action',
  SelectedIndex = 'Selected Index',
  TestName = 'Test Name',
  SectionName = 'Section Name',
  Revisited = 'Revisited',
  TimeRemaining = 'Time Remaining',
  FromIndex = 'From Index',
  ToIndex = 'To Index',
  AssessmentType = 'Assessment Type',

  // Media
  VideoId = 'Video Id',
  VideoTimestamp = 'Video Timestamp',

  // Teacher Dashboard Assignments V3
  NewAssignmentIdCreated = 'New Assignment Id Created',
  AssignmentName = 'Assignment Name',
  AssignmentStatus = 'Assignment Status',
  ModifiedFields = 'Assignment Modified Fields',
  SelectedItems = 'Selected Items',
  AssignedNuggets = 'Assigned Nuggets',
  FiltersUsed = 'Filters Used',
  AttemptedNuggets = 'Attempted Nuggets',
  CompletionCount = 'Completion Count',
  CompletionTotal = 'Completion Total',
  Successes = 'Successes',
  Failures = 'Failures',
  DuplicatedNewIds = 'Duplicated New Ids',
  ClassesNames = 'Classes Names',
  ClassesIds = 'Classes Ids',
  StartDate = 'Start Date',
  StartTime = 'Start Time',
  DueDate = 'Due Date',
  DueTime = 'Due Time',
  GroupType = 'Group Type',
  GroupId = 'Group Id',
  GroupAmount = 'Group Amount',
  CreateStep = 'Create Step',

  // Common Keys
  Navigate = 'Navigate',
  PageNumber = 'Page Number',
  // Learner Journey
  Mode = 'Mode',
  TopicId = 'Topic Id',
  TopicName = 'Topic Name',

  // Teacher Tools
  RevealAnswers = 'Reveal Answers',
  SeedQuestionId = 'Seed Question ID',
  PromptVersion = 'Prompt Version',
  RegenerateReason = 'Regenerate Reason',
  RegenerateDescription = 'Regenerate Description',
  LearnersAssigned = 'LearnersAssigned',
}

type BasicMixpanelValues = string | number | boolean;

export type MixpanelValues = BasicMixpanelValues | BasicMixpanelValues[];
