import { GET } from 'century-core/core-apis/utils';
import { getLearnURL } from '../learn';

export interface ClassPropertiesMeta {
  classProperties: Ctek.ClassProperties;
  _id: string;
  name: string;
}

export type GetClassesContext = 'all' | 'teach' | 'admin';

export function validateClassCode(classCode: string) {
  const url = getLearnURL(`/classes/code/${classCode}`);

  return GET<{ isValid: boolean; classId: string; orgId: string }>({ url }).catch((err: Error) => {
    throw Error(`Unable to validate class code ${classCode}, ${err}`);
  });
}

export function getClasses(
  accessToken: string,
  classCat: string,
  orgId: string,
  context: GetClassesContext = 'teach',
  signal?: AbortSignal
) {
  const url = getLearnURL('/classes/');
  url.search += `?context=${context}`;
  url.search += `&orgId=${orgId}`;
  url.search += `&classCategoryId=${classCat}`;

  return GET<Ctek.Class[]>({ url, token: accessToken, signal }).catch((err: Error) => {
    throw Error(`Unable to get classes for org id ${orgId} in class category ${classCat}, ${err}`);
  });
}

export function getAssessmentClassById(accessToken: string, classId: string) {
  const url = getLearnURL(`/classes/smart-assessment-class/${classId}`);
  url.search += '?select=classProperties,name,organisation';

  return GET<ClassPropertiesMeta>({ url, token: accessToken }).catch((err: Error) => {
    throw Error(`Unable to get assessment cohort: ${classId}, ${err}`);
  });
}
